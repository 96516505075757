
import React from "react";
import NavbarAfter from "./Navbar_after";

function Common() {
  return (
    <>
      <NavbarAfter />
    </>
  );
}

export default Common;
